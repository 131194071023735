import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useRef } from 'react';

function MainFeaturedPost(props) {
  // const { post } = props;

  return (
    <Grid container justifyContent="center" spacing={3} style={{ 'marginTop': '1%' }}>
      <Grid item md={8} xs={10}>
        <Typography variant="h6" align="left">
          <b>LÍNEA ÉTICA PEDREGAL</b>
        </Typography>
        <Typography variant="body1" paragraph align="left">
          La Línea Ética Pedregal permite reportar conductas en contra de nuestro Código de Ética y Conducta Empresarial.
          El reporte de estas conductas se realiza de manera anónima y el tratamiento de la información será confidencial.
          Todos los asuntos reportados serán evaluados.
        </Typography>
        <Grid container spacing={3} style={{ 'marginTop': '3%' }}>
          <Grid item md={6} >
            <Typography variant="h6" align="left" style={{ 'color': '#db091a' }}>
              <b>REGISTRAR NUEVO REPORTE</b>
            </Typography>
            <Typography variant="body1" paragraph align="left">
              La información que se registre en el siguiente formulario será recibida por un Comité de Ética,
              el cual cuenta con miembros especializados,
              quienes garantizan la confidencialidad de los denunciantes.
            </Typography>
          </Grid>
          <Grid item md={6} >
            <Typography variant="h6" align="left" style={{ 'color': '#db091a' }}>
              <b>CONSULTAR REPORTE</b>
            </Typography>
            <Typography variant="body1" paragraph align="left">
              En esta sección podrás consultar el estado del reporte realizado. Solo debes colocar el código generado.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ 'marginTop': '3%' }} >
          <Grid item md={6} xs={6}>
            <Button variant="contained" style={{ 'width': '90%', 'backgroundColor': '#db091a' }} href="/createreport">Registrar Reporte</Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button variant="contained" style={{ 'width': '90%', 'backgroundColor': '#db091a' }} href="/searchreport">Consultar Reporte</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // </Paper>
  );
}

MainFeaturedPost.propTypes = {
  // post: PropTypes.shape({
  //   description: PropTypes.string.isRequired,
  //   image: PropTypes.string.isRequired,
  //   imageText: PropTypes.string.isRequired,
  //   linkText: PropTypes.string.isRequired,
  //   title: PropTypes.string.isRequired,
  // }).isRequired,
};

export default MainFeaturedPost;
