import logo from './logo.svg';
import * as React from 'react';
import './App.css';
import Blog from './components/blog/Blog';
import CreateReport from './components/forms/CreatedReport'
import SearchReport from './components/forms/SearchReport'
import SuccessReport from './components/forms/SuccessReport'
import ReportDetail from './components/admin/ReportDetail'
import Report from './components/admin/Report'
import { HashRouter, Redirect, Link } from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './components/signin/SignIn';
import { useEffect, useRef, useState } from 'react';


const router = createBrowserRouter([
  { path: "/", element: <Blog />, },
  { path: "/login", element: <Login />, },
  { path: "/createreport", element: <CreateReport />, },
  { path: "/searchreport", element: <SearchReport />, },
  { path: "/successreport", element: <SuccessReport />, },
]);



function App() {
  const [token, setToken] = React.useState('');

  useEffect(() => {
    let ls_token = JSON.parse(localStorage.getItem('token'))
    setToken(ls_token)
  }, []);

  return (
    // <div className="App">
    //   <Blog/>

    // </div>
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Blog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/createreport" element={<CreateReport />} />
          <Route path="/searchreport" element={<SearchReport />} />
          <Route path="/successreport/:id" element={<SuccessReport />} />

          {token ?
            <>
              <Route path="/report" element={<Report />} />
              <Route path="/report/:id" element={<ReportDetail />} />
            </>
            :
            <></>
          }
          <Route component={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
